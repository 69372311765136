//import React, { useState } from 'react';

import React from 'react';

const Contact = () => {
    return (
        <div>
            <h2>お問い合わせ</h2>
            <p>下記のフォームからお問い合わせください。</p>
            <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSc5HsQGK5KgEancTwnoVtF_8buCgdru7omE5QU1dtENOMNoxA/viewform?usp=sf_link" // ここにコピーしたリンクを貼り付けてください
                width="640"
                height="800"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
            >
                読み込んでいます...
            </iframe>
        </div>
    );
};

//export default Contact;


export default Contact;



//https://docs.google.com/forms/d/e/1FAIpQLSc5HsQGK5KgEancTwnoVtF_8buCgdru7omE5QU1dtENOMNoxA/viewform?usp=sf_link