import React from 'react';

const About = () => {
    return (
        <div>
            <h1>会社概要</h1>
            <p>
                私たちの企業は、創立以来、テクノロジーと革新を通じてクライアントの成功を支援してきました。
                スマートフォンアプリやデータ分析サービスに特化し、業界のリーダーとして成長を続けています。
            </p>
            <p>
                当社の使命は、お客様に価値を提供し、持続可能な成長を実現することです。
                私たちは、お客様のビジョンを理解し、共に成長するパートナーであることを誇りに思っています。
            </p>
        </div>
    );
};

export default About;

