// src/components/Header.js
import React from 'react';
import { Link } from 'react-scroll'; // react-scrollを使用

const Header = () => {
    return (
        <header>
            <h1>EY007</h1>
            <nav>
                <ul>
                    <li><Link to="home" smooth={true} duration={500}>ホーム</Link></li>
                    <li><Link to="about" smooth={true} duration={500}>会社概要</Link></li>
                    <li><Link to="services" smooth={true} duration={500}>サービス</Link></li>
                    <li><Link to="contact" smooth={true} duration={500}>お問い合わせ</Link></li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;





