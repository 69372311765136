import React from 'react';

const Services = () => {
    return (
        <div>
            <h1>サービス</h1>
            <h2>スマートフォンアプリ開発</h2>
            <p>
                直感的なユーザーインターフェースを持つ高品質なスマートフォンアプリを開発し、ユーザー体験を向上させます。
            </p>
            <h2>Excel VBAプログラミング</h2>
            <p>
                業務効率化のためのカスタムVBAソリューションを提供し、Excelを使ったデータ管理をサポートします。
            </p>
            <h2>データ分析業務</h2>
            <p>
                データから価値あるインサイトを引き出し、戦略的な意思決定をサポートします。
                統計解析やデータ可視化の技術を駆使して、ビジネスの成長を加速させます。
            </p>
        </div>
    );
};

export default Services;

