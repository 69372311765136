import React from 'react';

const Home = () => {
    return (
        <div>
            <h1>私たちの企業へようこそ！</h1>
            <p>
                私たちは、スマートフォンアプリ開発、Excel VBAプログラミング、データ分析業務に特化した企業です。
                先進的な技術と豊富な経験を活かし、クライアントのビジネスをサポートします。
            </p>
            <p>
                私たちのチームは、お客様のニーズを理解し、最適なソリューションを提供することに情熱を注いでいます。
                常に変化する市場のニーズに応えるため、最先端の技術を取り入れ、最高のサービスを提供します。
            </p>
            <p>
                あなたのビジネスを次のレベルへ引き上げるために、私たちにお手伝いさせてください。
            </p>
        </div>
    );
};

export default Home;


