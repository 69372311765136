// src/App.js
import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import './styles.css'; // スタイルのインポート

const App = () => {
    return (
        <div>
            <Header />
            <main className="main">
                {/* 各セクションにIDを設定 */}
                <section id="home">
                    <Home />
                </section>
                <section id="about">
                    <About />
                </section>
                <section id="services">
                    <Services />
                </section>
                <section id="contact">
                    <Contact />
                </section>
            </main>
            <Footer />
        </div>
    );
};

export default App;
